import React, { useContext } from 'react'
import useCollapse from "react-collapsed";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";
import "./mobileNavAccordion.scss";
import { Context } from '../../../redux/store';
import { useLocation } from "@reach/router";

function MobileNavAccordion({ outerNav, innerNav, icons, onExpanded, isExpanded }) {
  const { dispatch } = useContext(Context);
  const location = useLocation();
  // const [isExpanded, setExpanded] = React.useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
    duration: 500
  });

  const handleLinkClick = () => {
    dispatch({ type: "SET_HAMBURGER_OPEN", payload: false, });
  }

  if (!(innerNav.length > 0)) {
    return (
      <div className="moileAccordion__section">
        <div className={`moileAccordion`}>
          <a
            href={outerNav?.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}
            dangerouslySetInnerHTML={{ __html: outerNav.title }}
            className="moileAccordion__title"
            activeClassName="active"
          >
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className={`moileAccordion__section ${location.pathname.startsWith('/' + outerNav.navId) ? 'active-section' : ''}`}>
      <div className={`moileAccordion ${isExpanded ? "active" : ""}`}>
        <a
          href={outerNav?.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
          dangerouslySetInnerHTML={{ __html: outerNav.title }}
          className="moileAccordion__title"
          activeClassName="active"
        >
        </a>
        <span className="moileAccordion__icon"
          {...getToggleProps({
            style: {},
            onClick: onExpanded
          })}
        >
          {(innerNav.length > 0) && (isExpanded ? icons?.closeIcon ?? <TfiAngleUp /> : icons?.openIcon ?? <TfiAngleDown />)}
        </span>
      </div>

      <div {...getCollapseProps({ style: {} })}>
        {(innerNav.length > 0) && innerNav.map((item) => (
          <>
            <a
              to={item.url}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
              onClick={handleLinkClick}
              className="moileAccordion__subTitle"
              activeClassName="active"
            />
          </>
        ))}
      </div>
    </div>
  );
}

export default MobileNavAccordion;