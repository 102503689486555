import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import HcpIsi from "./isi/index";
import GhostIsi from "../why-wakix/isi";
import "../../sass/styles.scss";
import useDeviceSize from "../../hooks/use-device-size";

function Layout({
    pageClass,
    children,
    ReferenceList,
    footerText,
    jobCode,
    copyRightYear,
    callLinkNeeded,
    noStickyHCPLocatorCTA,
    isGhostPage = false,
}) {
    const [elmRef, setElmRef] = useState(null);
    const footerRef = useRef(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useLayoutEffect(() => {
        if (typeof window !== "undefined") {
            const imgs = window.document.getElementsByTagName("img");
            const imgPromises = [];

            Array.from(imgs).forEach(function (img) {
                if (!img.hasAttribute("alt")) {
                    img.setAttribute("alt", "Description of the image");
                    if (img.getAttribute("src").indexOf("contextweb") !== -1) {
                        img.setAttribute("width", 1);
                        img.setAttribute("height", 1);
                    }
                }

                const imgPromise = new Promise((resolve) => {
                    img.onload = () => resolve();
                });

                imgPromises.push(imgPromise);
            });

            Promise.all(imgPromises).then(() => {
                setImagesLoaded(true);
            });
        }
    }, []);

    useEffect(() => {
        setElmRef(footerRef.current);

        // Add script to hide sticky-liveSupport on ghost pages on mobile
        const isGhostPage =
            document.body.getAttribute("data-ghost-page") === "true";
        const isMobile = window.innerWidth <= 768;
        if (isGhostPage && isMobile) {
            const stickyLiveSupport = document.querySelector(
                ".sticky-liveSupport"
            );
            if (stickyLiveSupport) {
                stickyLiveSupport.style.display = "none";
            }
        }
    }, []);

    const deviceSizes = useDeviceSize();
    const isMdUp = deviceSizes?.mdUp;

    const isOrphanPage = pageClass && pageClass.includes("orphan-page");

    const calculatedCallLinkNeeded = isOrphanPage
        ? callLinkNeeded
        : callLinkNeeded === true;

    const IsiComponent = isGhostPage ? GhostIsi : HcpIsi;

    const shouldHideStickyLiveSupport = () => {
        const hiddenPaths = [
            "/talking-treatment",
            "/ess",
            "/patient-starter-kit",
            "/treatment-goals",
            "/discussion-guide/personalize",
            "/discussion-guide",
            "/share-your-story",
            "/inspiration-builder",
            "/survey",
        ];

        if (typeof window !== "undefined") {
            let currentPath = window.location.pathname;
            if (currentPath.endsWith('/')) {
                currentPath = currentPath.slice(0, -1);
            }
            return hiddenPaths.some((path) => currentPath === path);
        }
        return false;
    };

    const showStickyLiveSupport =
        !noStickyHCPLocatorCTA &&
        !shouldHideStickyLiveSupport() &&
        !isGhostPage;

    return (
        <div
            className={`hcp-layout ${pageClass || ""}`}
            data-ghost-page={isGhostPage.toString()}
        >
            {deviceSizes?.mdUp ? (
                <Header isGhostPage={isGhostPage} />
            ) : (
                <Header
                    noStickyHCPLocatorCTA={noStickyHCPLocatorCTA}
                    isGhostPage={isGhostPage}
                />
            )}

            <div
                className={`content-container body-content-container ${showStickyLiveSupport && !isMdUp ? "bcc-top" : ""
                    }`}
            >
                <main className="body-content">{children}</main>
            </div>
            {elmRef && (
                <IsiComponent
                    footerRef={elmRef}
                    callLinkNeeded={calculatedCallLinkNeeded}
                    noStickyHCPLocatorCTA={noStickyHCPLocatorCTA}
                    isGhostPage={isGhostPage}
                    showStickyLiveSupport={showStickyLiveSupport}
                />
            )}
            {ReferenceList && ReferenceList}
            <div ref={footerRef}>
                <Footer
                    footerText={footerText}
                    jobCode={jobCode}
                    copyRightYear={copyRightYear}
                />
            </div>
        </div>
    );
}

export default Layout;
