import React, { useState, useEffect, useContext } from "react";
import navSchema from "./schema.json";
import { BsChevronDown } from "react-icons/bs";
import MobileNavAccordion from "../Accordion/mobileNavAccordion";
import { Context } from "../../../redux/store";
import { FaMinus, FaPlus } from "react-icons/fa";
import { TbArrowUpRight } from "react-icons/tb";
import { useLocation } from "@reach/router";

const GoToHCPMobile = () => {
  let envUrl = " ";
  if (typeof window !== "undefined") {
    if (window.location.href.indexOf("wakix.com") > -1) {
      envUrl = "http://www.wakixhcp.com";
    } else if (window.location.href.indexOf("persistent-pegasus") > -1) {
      envUrl = "https://develop--wakix-hcp-space-cheeta-b159e5.netlify.app";
    } else {
      envUrl = "https://develop--wakix-hcp-space-cheeta-b159e5.netlify.app";
    }
  }
  return (
    <a href={envUrl} target="_blank" rel="noopener noreferrer">
      For Healthcare Professionals
      <TbArrowUpRight />
    </a>
  );
};

const getNav = (navList, parent) => {
  let navResult = navList.filter((item) => item.parentId === parent);
  return navResult;
};

const getNavChildren = (_parentId) =>
  _parentId
    ? navSchema.filter((_navItem) => _navItem.parentId === _parentId)
    : [];

function Nav() {
  const { state } = useContext(Context);
  const location = useLocation();
  const [mobNavExpanded, setMobNavExpanded] = useState();
  useEffect(() => {
    !state.hamburgerOpen && setMobNavExpanded();
  }, [state.hamburgerOpen])

  return (
    <>
      <nav>
        <div className="centered-content">
          <div className="navbar desktop">
            {
              <ul className="level-1">
                {getNav(navSchema, null).map((item) => (
                  <li
                    key={item.navId}
                    className={`${location.pathname.startsWith("/" + item.navId)
                      ? "active"
                      : ""
                      }`}
                  >
                    <a href={item.url} partiallyActive={true} rel="noopener noreferrer" target="_blank">
                      <span
                        dangerouslySetInnerHTML={{ __html: item.title }}
                        className="nav-span"
                      />{" "}
                      {getNavChildren(item.navId).length > 0 && (
                        <BsChevronDown />
                      )}
                    </a>
                    <SubNavigation _itemId={item.navId} />
                  </li>
                ))}
              </ul>
            }
          </div>
        </div>
        <div
          className={`mobile-nav__wrap ${state.hamburgerOpen ? "active" : ""}`}
        >
          <div className="centered-content">
            <div className="mobile-utitlityNav">
              <ul>
                <li>
                  <a href="https://wakix.com/sign-up" target="_blank" rel="noopener noreferrer">
                    Sign Up
                  </a>
                </li>
                <li>
                  <GoToHCPMobile />
                </li>
              </ul>
            </div>
          </div>
          {getNav(navSchema, null).map((item, index) => (
            <div className={`mobile-nav`}>
              <MobileNavAccordion
                outerNav={item}
                innerNav={getNavChildren(item.navId)}
                icons={{ openIcon: <FaPlus aria-label="Expand" />, closeIcon: <FaMinus aria-label="Collapse" /> }}
                onExpanded={() => setMobNavExpanded(index === mobNavExpanded ? undefined : index)}
                isExpanded={index === mobNavExpanded}
                aria-label="Expand navigation" // Added aria-label here
              />
            </div>
          ))}
        </div>
      </nav>
    </>
  );
}

export default Nav;

function SubNavigation({ _itemId }) {
  return getNavChildren(_itemId) ? (
    <ul className="level-2" aria-label="Sub-navigation">
      {getNavChildren(_itemId).map((item) => (
        <li
          key={item.navId}
          className={`sub-nav ${getNavChildren(item.navId) ? "add-border-bottom" : ""
            }`}
        >
          {item.navId === "find-a-healthcare-provider" ? (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{ __html: item.title }}
              activeClassName="active"
            />
          ) : item.navId === "find-a-sleep-specialist" ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
              aria-label={item.title}
              dangerouslySetInnerHTML={{ __html: item.title }}
              activeClassName="active"
            />
          ) : (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={item.title}
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
              activeClassName="active"
            // partiallyActive={true}
            />
          )}

          {getNavChildren(item.navId) && (
            <ul className="level-3">
              {getNavChildren(item.navId).map((item) => (
                <li key={item.id}>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    activeClassName="active"
                    partiallyActive={true}
                    aria-label={item.title}
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  ) : null;
}
