import React from "react";
import { Row, Col } from "react-grid-system";
import FooterLogo from "../../images/footer-logo.webp";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import ExternalLink from "../external-link";

import { triggerCustomGTM } from "../../utils/helper";
import LazyLoad from "react-lazy-load";
import { useSiteMetadata } from "../../hooks/use-site-metadata";

const globalFootText = (copyRightYear) => (
    <>
        <p>
            WAKIX is a registered trademark and WAKIX for You is a trademark of
            Bioprojet Europe, Ltd.{" "}
        </p>
        <p>Harmony Biosciences name and logo are registered trademarks.</p>
        <p>&copy; {copyRightYear} Harmony Biosciences. All rights reserved.</p>
    </>
);

const HcpFooter = ({ footerText, jobCode, copyRightYear = "2023" }) => {
    const { externalLinkInfo } = useSiteMetadata();
    return (
        <div className="centered-content">
            <div className="footer-wrapper">
                <Row className="row">
                    <Col lg={2} md={12}>
                        <ExternalLink
                            externalURL="https://www.harmonybiosciences.com/"
                            messageTitle={externalLinkInfo.TITLE}
                            messageBody={externalLinkInfo.BODY}
                            classNameLink={
                                externalLinkInfo.LINK_CLASS_NAME + "footer-logo"
                            }
                            classNameOverlay={
                                externalLinkInfo.OVERLAY_CLASS_NAME
                            }
                        >
                            <LazyLoad offset={300} debounce={false}>
                                <img
                                    className="footer-img"
                                    src={FooterLogo}
                                    alt="Harmony Biosciences logo"
                                    width="149"
                                    height="117"
                                />
                            </LazyLoad>
                        </ExternalLink>
                    </Col>
                    <Col lg={8}>
                        <div className="footer-desc">
                            <ul className="footer-menu">
                                <li className="first">
                                    <ExternalLink
                                        externalURL="https://www.harmonybiosciences.com/"
                                        messageTitle={externalLinkInfo.TITLE}
                                        messageBody={externalLinkInfo.BODY}
                                        classNameLink={
                                            externalLinkInfo.LINK_CLASS_NAME
                                        }
                                        classNameOverlay={
                                            externalLinkInfo.OVERLAY_CLASS_NAME
                                        }
                                    >
                                        About Harmony Biosciences
                                    </ExternalLink>
                                </li>
                                <li>
                                    <a
                                        href="https://wakix.com/terms-of-use"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Terms of Use
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://wakix.com/privacy-policy"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://wakix.com/contact-us"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <div className="social-links">
                                        <span className="no-underline">
                                            Follow Us
                                        </span>
                                        <ExternalLink
                                            externalURL="https://www.facebook.com/WAKIX"
                                            messageTitle={
                                                externalLinkInfo.TITLE
                                            }
                                            messageBody={externalLinkInfo.BODY}
                                            classNameLink={
                                                externalLinkInfo.LINK_CLASS_NAME +
                                                " facebook-icon"
                                            }
                                            classNameOverlay={
                                                externalLinkInfo.OVERLAY_CLASS_NAME
                                            }
                                            onClick={() =>
                                                triggerCustomGTM({
                                                    event: "gtm.click",
                                                    customTag:
                                                        "Follow Us Facebook",
                                                })
                                            }
                                        >
                                            <FaFacebookF
                                                alt="Follow us on Facebook icon"
                                                aria-hidden="true"
                                            />
                                        </ExternalLink>

                                        <ExternalLink
                                            externalURL="https://www.instagram.com/wakix_pitolisant/"
                                            messageTitle={
                                                externalLinkInfo.TITLE
                                            }
                                            messageBody={externalLinkInfo.BODY}
                                            classNameLink={
                                                externalLinkInfo.LINK_CLASS_NAME +
                                                " insta-icon"
                                            }
                                            classNameOverlay={
                                                externalLinkInfo.OVERLAY_CLASS_NAME
                                            }
                                            onClick={() =>
                                                triggerCustomGTM({
                                                    event: "gtm.click",
                                                    customTag:
                                                        "Follow Us Facebook",
                                                })
                                            }
                                        >
                                            <FaInstagram
                                                alt="Follow us on Instagram icon"
                                                aria-hidden="true"
                                            />
                                        </ExternalLink>
                                    </div>
                                </li>
                            </ul>
                            <p>
                                {footerText
                                    ? footerText
                                    : globalFootText(copyRightYear)}
                                {jobCode ?? "US-WAK-2300215/Sep 2023"}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default function Footer({
    footerText,
    jobCode,
    copyRightYear,
    ...props
}) {
    return (
        <footer role="contentinfo" {...props}>
            <HcpFooter
                jobCode={jobCode}
                footerText={footerText}
                copyRightYear={copyRightYear}
            />
        </footer>
    );
}

Footer.propTypes = {};
