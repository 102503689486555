import React, { useContext } from "react";
import { Portal } from "react-portal";
import Nav from "./nav";
import AnchorLink from "react-anchor-link-smooth-scroll";
import brandLogo from "../../images/wakix-logo.webp";
import { AiOutlineMenu } from "react-icons/ai";
import { TbArrowUpRight } from "react-icons/tb";
import { TfiAngleRight } from "react-icons/tfi";
import { RxCross2 } from "react-icons/rx";
import { Context } from "../../redux/store";
import { BsDownload } from "react-icons/bs";
import CookieBanner from "../../components/why-wakix/cookie-banner";

const GoToHCP = () => {
  let envUrl = " ";
  if (typeof window !== "undefined") {
    if (window.location.href.indexOf("wakix.com") > -1) {
      envUrl = "http://www.wakixhcp.com";
    } else if (window.location.href.indexOf("persistent-pegasus") > -1) {
      envUrl = "https://develop--wakix-hcp-space-cheeta-b159e5.netlify.app";
    } else {
      envUrl = "https://develop--wakix-hcp-space-cheeta-b159e5.netlify.app";
    }
  }
  return (
    <a href={envUrl} target="_blank" rel="noopener noreferrer">
      For Healthcare Professionals
      <TbArrowUpRight />
    </a>
  );
};

const HCPLocator = () => {
  return (
    <div className="live-support">
      <div className="centered-content">
        <span>
          <a href="https://wakix.com/resources-and-support/find-a-healthcare-provider" rel="noopener noreferrer"
            target="_blank">
            Find a healthcare provider near you
            <TfiAngleRight />
          </a>
        </span>
      </div>
    </div>
  );
};

function Header({ noStickyHCPLocatorCTA }) {
  const { state, dispatch } = useContext(Context);
  const handleHamburgerClick = () => {
    dispatch({ type: "SET_HAMBURGER_OPEN", payload: !state.hamburgerOpen });
  };

  //   const [cookies, setCookie] = useCookies(["cookie-disclaimer"]);

  return (
    <>
      <header className="content-container">
        <div className="utility-nav-block">
          <div className="header-wrap">
            <div className="nav-list">
              <ul>
                <li>
                  <a
                    href="/pdf/wakix-tablets-pi.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pro-regular"
                  >
                    Prescribing Information
                  </a>
                </li>
                <li className="seperator">|</li>
                <li>
                  <AnchorLink href="#isi" offset={185}>
                    Important Safety Information
                  </AnchorLink>
                </li>
                <li>
                  <a href="https://wakix.com/sign-up/" target="_blank" rel="noopener noreferrer">
                    Sign Up
                  </a>
                </li>
                <li>
                  <GoToHCP />
                </li>
                <li>
                  <a
                    href="https://wakix.com/crm/wakix-prescription-referral-form"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mobile-referral-btn"
                  >
                    WAKIX Prescription Referral{" "}
                    <nobr
                      style={{
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        color: "inherit",
                      }}
                    >
                      Form <TfiAngleRight className="icon-right" />
                    </nobr>
                  </a>
                </li>
              </ul>
            </div>
            <div className="centered-content site-header__top">
              <div className="logo-container">
                <a
                  href="https://wakix.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="logo"
                  aria-label="Wakix logo"
                >
                  <img src={brandLogo} alt="Wakix Logo" width="160" height="84" />
                </a>
              </div>
              <div className="nav-list nav-list--alt">
                <ul>
                  <li>
                    <a
                      href="/pdf/wakix-tablets-pi.pdf"
                      className="pro-regular"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Prescribing Information
                    </a>
                  </li>
                  <li>
                    <AnchorLink href="#isi" offset={155}>
                      Important Safety Information
                    </AnchorLink>
                  </li>
                  <li>
                    <a
                      href="https://wakix.com/sign-up"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sign Up
                    </a>
                  </li>
                  <li>
                    <GoToHCP />
                  </li>
                  <li>
                    <a
                      href="https://wakix.com/crm/wakix-prescription-referral-form"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WAKIX Prescription Referral Form{" "}
                      <BsDownload className="icon-download" />{" "}
                      {/*  <TfiAngleRight className="icon-right" /> */}
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={`btn-menu ${state.hamburgerOpen ? "btn-menu__active" : ""
                  }`}
              >
                <button onClick={handleHamburgerClick}>
                  menu
                  {state.hamburgerOpen ? (
                    <RxCross2 className="icon-cross" />
                  ) : (
                    <AiOutlineMenu />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="site-header__bottom">
            <Nav />
          </div>
        </div>
        {/* <div className="live-support">
        <div className="centered-content">
          <span>
            Live Support<Link to="#">855-WAKIX-INFO</Link>
          </span>
        </div>
      </div> */}
        {!noStickyHCPLocatorCTA && <HCPLocator />}
      </header>
      <Portal>
        <CookieBanner />
      </Portal>
    </>
  );
}

export default Header;
