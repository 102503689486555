import * as React from "react";
import { useCookies } from "react-cookie";

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(["cookie-disclaimer"]);

  const handleAcceptCookie = (event) => {
    // prevent default action
    event.preventDefault();
    setCookie("cookie-disclaimer", "visited");
  }

  const handleKeyPress = (event) => {
    // trigger on pressing "Enter" or "Space" key
    if (event.key === 'Enter' || event.key === ' ') {
      handleAcceptCookie(event);
    }
  }

    // Determine the position based on the prop
    const bannerPosition =  '63vh';


  return (
    <div className="cookie-banner-wrapper why-wakix-cookie">
      {!cookies["cookie-disclaimer"] && (
        <div className="cookie-message-popup"  style={{ top: bannerPosition }}>
          This website uses cookies to improve the site and your experience. By
          continuing to browse this website, you are agreeing to accept our use
          of cookies. To learn more about our Privacy Policy,{" "}
          <a href="/privacy-policy" target="_blank" title="Read more about our Privacy Policy" aria-label="Read more about our Privacy Policy">click here
          <span className="visually-hidden">to read our Privacy Policy</span></a>.
          <button 
            className="accept-cookie"
            onClick={handleAcceptCookie}
            onKeyPress={handleKeyPress}
            tabIndex="0"
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default CookieBanner;
